export interface AppsEmptyStateInfo {
  icon: any
  title: string
  description: string
}

export interface ApiVersionHasRegistration {
  apiId: string
  versionId: string
  registrationId: string
}

export enum CREDENTIAL_TYPE {
  KEY_AUTH = 'key_auth',
  SELF_MANAGED = 'self_managed_client_credentials',
  CLIENT = 'client_credentials',
}
